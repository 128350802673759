@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  display: flex;
  gap: 8px;
  justify-content: center;

  @include for-mobile-screens {
    flex-direction: column;
    width: 100%;
  }
}
