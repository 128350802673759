@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  width: 100%;
  padding-top: var(--sectionPaddingSmall);
  padding-bottom: var(--sectionPaddingSmall);

  @include for-mobile-screens {
    padding-top: var(--sectionPaddingMedium);
    padding-bottom: var(--sectionPaddingSmall);
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 96px;
    text-align: center;
    background-color: var(--zest3);
    border-radius: 24px;

    @include for-mobile-screens {
      border-radius: 10px;
      padding: 56px 16px 16px;
    }
  }

  .title {
    margin-bottom: 32px;
  }

  p {
    max-width: 450px;
    margin: 0 auto;
    color: var(--grey5);
  }
}

.buttons {
  margin-top: 48px;
}
